<template>
    <div class="background">
        <div class="circle-container" v-for="item in 100" :key="item">
            <div class="circle" :class="item"></div>
        </div>
    </div>
</template>

<script>
export default {
name: "Background"
}
</script>

<style scoped>

</style>
