<template>
    <div class="loading">
        <div class="infinity-loader">
            <div class="bg">
                <!--background circles-->
                <div class="left-bg"></div>
                <div class="right-bg"></div>
            </div>
            <div class="fg">
                <!--foreground circles-->
                <div class="top-left-rect">
                    <div></div>
                </div>
                <div class="bottom-right-rect">
                    <div></div>
                </div>
                <div class="top-right-rect">
                    <div></div>
                </div>
                <div class="bottom-left-rect">
                    <div></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>

<style scoped>

</style>
