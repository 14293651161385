<template>
  <div id="app">
      <div class="main-container container">
        <router-view/>
      </div>
      <Background v-if="enableBackground"></Background>

      <footer><div class="version">v{{version}}</div></footer>
  </div>
</template>

<style lang="scss">
    @import "@/scss/game.scss";
</style>
<script>
import Background from "./components/Background";
export default {
    components: {Background},
    data: () => ({
        version: process.env.VUE_APP_VERSION
    }),
    computed: {
        enableBackground() {
            // Omdat die animatie de mac nogal doet laten blazen, alleen tijdens de wachtschermen
            return this.$route.name === 'Login' || (this.$store.state.game && this.$store.state.game.status === 'pending');
        }
    }
}
</script>
