import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);

Vue.config.productionTip = false

console.log('%cQuizUt %cGame Client\r\n' +
    '%cVersion: %c'+process.env.VUE_APP_VERSION+'\r\n' +
    '%cAPI URL: %c'+process.env.VUE_APP_API_URL+'\r\n' +
    '%cWebSocket URL: %c'+process.env.VUE_APP_WEBSOCKET_URL,
    'color: #B10DC9; font-weight: bold; font-size: 1.3rem;', 'color: #F012BE; font-weight: bold; font-size: 1.3rem;',
    'color: #FF851B', 'color: #FF4136',
    'color: #FF851B', 'color: #FF4136',
    'color: #FF851B', 'color: #FF4136');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
