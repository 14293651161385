import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import throttle from "lodash/throttle";

Vue.use(Vuex)

let socket;
let countdownTimer;

/* global io */

export default new Vuex.Store({
    state: {
        game: null,
        team: null,
        question: null,
        answers: [],
        submittedAnswers: [],
        answerStates: [],
        isSubmitting: false,
        hasWebsocketError: false,
        countdownTimer: {
            enabled: false,
            time: null,
            endsAt: null,
            timeRemaining: null,
            ended: false
        }
    },
    mutations: {
        SET_GAME(state, game) {
            state.game = Object.assign({}, state.game, game);
        },
        SET_TEAM(state, team) {
            state.team = Object.assign({}, state.team, team);
        },
        SET_QUESTION(state, question) {
            state.question = question;
        },
        SET_IS_SUBMITTING(state, isSubmitting) {
            state.isSubmitting = isSubmitting;
        },
        SET_ANSWERS(state, {answers, answerStates, submittedAnswers}) {
            if (answers) {
                state.answers = answers;
            }
            if (answerStates) {
                state.answerStates = answerStates;
            }
            if (submittedAnswers) {
                state.submittedAnswers = submittedAnswers
            }
        },
        SET_ANSWER(state, {answerIndex, answer, answerState}) {
            Vue.set(state.answers, answerIndex, answer);
            Vue.set(state.answerStates, answerIndex, answerState);
        },
        RESET_ANSWERS(state) {
            state.answers = [];
            state.answerStates = [];
            state.submittedAnswers = [];

            if (state.question) {
                for (var i = 0; i < state.question.answerCount; i++) {
                    state.answers.push('');
                    state.answerStates.push(null);
                }
            }
        },
        SET_WEBSOCKET_ERROR(state, hasError) {
            state.hasWebsocketError = hasError
        },
        UPDATE_COUNTDOWN_TIMER(state, data) {
            state.countdownTimer = data;
        },
        UPDATE_COUNTDOWN_TIME_REMAINING(state, data) {
            state.countdownTimer.timeRemaining += data;
        },
        RESET_COUNTDOWN_TIMER(state) {
            window.clearInterval(countdownTimer);
            state.countdownTimer = {
                enabled: false,
                time: null,
                endsAt: null,
                timeRemaining: null,
                ended: false
            };
        }
    },
    actions: {
        checkEntryCode(_, {code}) {
            console.log('%capp%c Checking entry code %c'+code, 'background-color: #5eba00; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', 'color: orange');

            return axios.post(process.env.VUE_APP_API_URL + '/login', {code: code})
                .then(response => {
                    return response.data
                })
        },
        initializeGame({dispatch}, {token}) {
            console.log('%capp%c Initializing team %c'+token, 'background-color: #5eba00; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', 'color: orange');

            return axios.get(process.env.VUE_APP_API_URL + '/' + token)
                .then(response => {
                    return dispatch('loadGameState', response.data);
                }).then(() => {
                    return dispatch('setupWebsocket');
                })
        },
        resetAnswers({commit}) {
            commit('RESET_ANSWERS');
        },
        setupWebsocket({commit, dispatch, state }) {
            if (typeof io === 'undefined') {
                commit('SET_WEBSOCKET_ERROR', true)
                return;
            }
            commit('SET_WEBSOCKET_ERROR', false);

            socket = io(process.env.VUE_APP_WEBSOCKET_URL);
            socket.on('connect', () => {
                console.log('%csocket%c Connected', 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
                socket.emit('join', {game: state.game.token, team: state.team.token});
            })

            socket.on('status', data => {
                console.log('%csocket%c Game Status update', 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', data);
                commit('SET_GAME', data.game);
            })

            socket.on('teamstatus', data => {
                console.log('%csocket%c Team Status update', 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', data);
                dispatch('loadGameState', data);
            })

            socket.on('countdown', data => {
                console.log('%csocket%c Countdown update', 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', data);
                dispatch('startInternalCountdown', data )
            })

            socket.on('typing', data => {
                console.log('%csocket%c Typing', 'background-color: #03A9F4; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888', data);
                Vue.set(state.answers, data.answerIndex, data.answer);
                Vue.set(state.answerStates, data.answerIndex, null);
            });
        },
        submitAnswers({state, dispatch, commit}) {
            commit('SET_IS_SUBMITTING', true);
            let answerArray = [];
            for (var i = 0; i < state.question.answerCount; i++) {
                answerArray.push(state.answers[i] || '');
            }
            commit('SET_ANSWERS', { submittedAnswers: answerArray });
            return axios.post(process.env.VUE_APP_API_URL+'/'+state.team.token, {
                qsq: state.question.number,
                answers: answerArray
            }).then(response => {
                dispatch('loadGameState', response.data);
            }).finally(() => {
                commit('SET_IS_SUBMITTING', false);
            })
        },
        loadGameState({commit}, gameState) {
            if (typeof gameState.answers !== 'undefined' || typeof gameState.answerStates !== 'undefined') {
                commit('SET_ANSWERS', {
                    answers: gameState.answers || [],
                    answerStates: gameState.answerStates || [],
                    submittedAnswers: gameState.answers || []
                })
            }
            if (gameState.question) {
                commit('SET_QUESTION', gameState.question);
            }
            if (gameState.team) {
                commit('SET_TEAM', gameState.team);
            }
            if (gameState.game) {
                commit('SET_GAME', gameState.game);
            }
        },
        setAnswer({state, commit}, {answerIndex, answer}) {
            commit('SET_ANSWER', {answerIndex: answerIndex, answer: answer, answerState: null});

            broadcastAnswer(
                answerIndex,
                answer,
                state.question.number
            );
        },
        // broadcastAnswer(_, {answerIndex, answer}) {
        //     // throttle(function (answerIndex, answer) {
        //         //let questionNumber = state.question.number
        //         //socket.emit('typing', {answerIndex, answer, questionNumber})
        //     // }, 1000, {leading: false})
        // }
        startInternalCountdown({commit, dispatch}, data) {
            // always stop current timer
            window.clearInterval(countdownTimer);

            if (data.enabled) {
                let endsAt = (new Date()).getTime() + (data.time * 1000);
                commit('UPDATE_COUNTDOWN_TIMER', { enabled: true, time: data.time, endsAt: endsAt, timeRemaining: data.time, ended: false});
                countdownTimer = window.setInterval(() => {
                    dispatch('updateInternalCountdownTimer')
                }, 1000)
            } else {
                commit('UPDATE_COUNTDOWN_TIMER', { enabled: false, time: null, endsAt: null, timeRemaining: null, ended: false});
            }
        },
        updateInternalCountdownTimer({state, commit}) {
            if (state.countdownTimer.timeRemaining > 1) {
                commit('UPDATE_COUNTDOWN_TIME_REMAINING', -1);
            } else {
                // ended
                window.clearInterval(countdownTimer);
                let newState = JSON.parse(JSON.stringify(state.countdownTimer));
                newState.timeRemaining = 0;
                newState.ended = true;
                commit('UPDATE_COUNTDOWN_TIMER', newState);
            }
        },
    },
    getters: {
        progressPercentage: (state, getters) => {
            if (!state.game) return 0;
            if (!state.question) return 0;

            let question = (getters.questionProgress - 1)
            return question / state.game.totalQuestionCount;
        },
        questionProgress: state => {
            if (!state.game) return 0;
            if (!state.question) return 0;
            if (state.game.gameState.question) return state.game.gameState.question;
            return state.question.number;
        },
        getAnswer: (state) => (answerIndex) => {
            return state.answers[answerIndex] || '';
        }
    }
})

const broadcastAnswer = throttle(function (answerIndex, answer, questionNumber) {
    if (socket) {
        socket.emit('typing', {answerIndex, answer, questionNumber})
    }
}, 1000, {leading: false});
