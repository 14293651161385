/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
        console.log('%cworker%c Service worker ready', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
    },
    registered () {
      console.log('%cworker%c Service worker has been registered.', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
    },
    cached () {
      console.log('%cworker%c Content has been cached for offline use.', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
    },
    updatefound () {
      console.log('%cworker%c New content is downloading.', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
    },
    updated () {
      console.log('%cworker%c New content is available; please refresh.', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
      window.location.reload(true);
    },
    offline () {
      console.log('%cworker%c No internet connection found. App is running in offline mode.', 'background-color: #354052; color: white; padding: 1px 3px; border-radius: 3px', 'color: #888');
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
