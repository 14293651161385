<template>
    <div class="login-view">
        <Loading v-if="isLoading" class="game-loader"></Loading>
        <div class="text-center position-relative logo">
            <img src="/img/logo-quizut-2020.png" alt="QuizUt" />
        </div>
        <div class="login-content position-relative">
            <div class="frame">
                <span class="left-top"></span>
                <span class="top"></span>
                <span class="right-top"></span>
                <span class="right"></span>
                <span class="right-bottom"></span>
                <span class="bottom"></span>
                <span class="left-bottom"></span>
                <span class="left"></span>
            </div>
            <form @submit.prevent="submit" class="position-relative">
                <div class="text-center">
                    <p class="intro-title">Vul hier de inlogcode in die je hebt ontvangen:</p>
                    <CodeInput
                        @complete="checkCode"
                        class="mx-auto"
                        :fields="4"
                        type="text"
                        :auto-focus="true"
                    ></CodeInput>
                    <p v-if="error" class="mt-4 mb-0 error-message">{{error}}</p>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import CodeInput from "vue-verification-code-input";
import Loading from "@/components/Loading";

export default {
    name: 'Login',
    components: {
        CodeInput,
        Loading
    },
    data: () => ({
        isLoading: false,
        entryCode: '',
        error: ''
    }),
    methods: {
        checkCode(v) {
            this.entryCode = v;
            this.submit();
        },
        submit() {
            this.isLoading = true;
            this.error = '';            
            setTimeout(function(){
                this.$store.dispatch('checkEntryCode', { code: this.entryCode})
                    .then(response => {
                        if (response.success) {
                            this.$router.push({name: 'Game', params: { token: response.team.token }});
                        } else {
                            this.error = 'Er is helaas geen spel gevonden met deze code';
                        }
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }.bind(this), 500);
            
        }
    }
}
</script>
